<template>
  <div class="search">
    <div class="ziHead"></div>

    <div class="width_100" id="Nav"></div>

    <div class="main_panel">
      <div class="navPage">
        <div id="PageLeft">
          <div class="Tit">
            <div class="word">搜索结果</div>
          </div>
          <div class="sort"></div>
        </div>

        <div id="PageRight">
          <div class="Nav">
            <a href="/" target="_blank"><i class="iconfont">&#xe689; </i></a>
            &nbsp;&nbsp;&nbsp;&nbsp;搜索关键词：<a>{{ searchValue | ellipsis2 }}</a>&nbsp;&nbsp;&nbsp;&nbsp; 搜索结果
          </div>
          <div id="NewsList">
            <ul class="comUl">
              <li v-for="(item, index) in searchList" :key="index">
                <a v-if="item.url && item.url !== ''" :href="item.url" target="_blank">
                  <span style="float: right;">{{ dayjs(item.publishDate).format('YYYY-MM-DD') }}</span>
                  <div class="searchTxt">{{ item.title | ellipsis }}</div>
                </a>

                <a v-else
                  :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`"
                  target="_blank">
                  <span style="float: right;">{{ dayjs(item.publishDate).format('YYYY-MM-DD') }}</span>
                  <div class="searchTxt">{{ item.title | ellipsis }}</div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div></div>
      </div>
      <div class="pagin">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          :pager-count="6"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { articleSearch } from '@/api/common'
export default {
  data() {
    return {
      searchList: [],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      searchValue: '',
    }
  },
  computed: {},
  filters: {
    //超过100位显示 ...
    ellipsis: function(value) {
      if (!value) return ''
      if (value.length > 53) {
        return value.slice(0, 53) + '...'
      }
      return value
    },
    ellipsis2: function(value) {
      if (!value) return ''
      if (value.length > 42) {
        return value.slice(0, 42) + '...'
      }
      return value
    },
  },
  mounted() {
    var _this = this
    _this.searchValue = _this.$route.params.title
    _this.articles()
  },
  methods: {
    articles() {
      var _this = this

      let data = {
        entTApplicationUuid: _this.webId,
        title: _this.searchValue,
        pageNum: _this.pageNum,
        pageSize: _this.pageSize
      }
      articleSearch(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.searchList = data.rows
          _this.total = data.totalRows
        }
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      var _this = this
      _this.pageNum = val
      _this.articles()
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'iconfont'; /* Project id 3165120 */
  src: url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.woff2?t=1644917438066') format('woff2'),
    url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.woff?t=1644917438066') format('woff'),
    url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.ttf?t=1644917438066') format('truetype');
}
.iconfont {
  font-family: 'iconfont' !important;
  font-size: 22px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.1px;
  -moz-osx-font-smoothing: grayscale;
}
.search {
  width: 100%;
  height: auto;
  overflow: hidden;
  font-family: '微软雅黑', 'Microsoft YaHei', sans-serif;
  font-size: 14px;
  min-height: 902px;
  margin-bottom: 20px;
}
.search .ziHead {
  width: 100%;
  height: 417px;
  overflow: hidden;
  background: url(../../assets/image/groupProfile0.jpg) center no-repeat;
  background-size: 100% 100%;
}
.search .main_panel {
  width: 1200px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
}
.navPage {
  display: block;
}

.search #Nav {
  position: absolute;
  height: 44px;
  z-index: -1;
  background-color: #f2f2f2;
}
.search #Nav {
  float: right;
  text-align: right;
  overflow: hidden;
  line-height: 40px;
  color: #636363;
  font-size: 14px;
}
.search .width_100 {
  width: 100% !important;
}
.search #PageLeft {
  width: 255px;
  float: left;
  overflow: hidden;
  margin-top: 3px;
  margin-right: 35px;
}
.search #PageLeft .Tit {
  width: 255px;
  height: 105px;
  color: #ffffff;
  z-index: 9999999;
  background: #0062b3 35px center no-repeat;
  margin-bottom: 2px;
}
.search #PageLeft .Tit .word {
  width: 155px;
  height: 105px;
  line-height: 105px;
  font-size: 26px;
  float: left;
  margin-left: 95px;
}
.search #PageLeft .sort {
  width: 100%;
  overflow: hidden;
}
.search #PageRight {
  width: 910px;
  // float: right;
  overflow: hidden;
  // margin-left: 35px;
}
.search #PageRight .Nav {
  width: 100%;
  height: 44px;
  line-height: 44px;
  overflow: hidden;
  color: #888888;
  margin-bottom: 10px;
}
.search #PageRight .Nav a {
  color: #888888;
}
a,
a:focus,
a:hover {
  text-decoration: none;
}
.search .comUl {
  padding-left: 0px;
}
.search .comUl li {
  width: 100%;
  height: 45px;
  line-height: 45px;
  margin: 0 auto;
}
.search .comUl li a {
  white-space: nowrap;
  display: block;
  text-align: left;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  overflow: hidden;
  color: #000000;
  font-size: 16px;

  //overflow: hidden;/*超出部分隐藏*/
  // text-overflow:ellipsis;/* 超出部分显示省略号 */
  // white-space: nowrap;/*规定段落中的文本不进行换行 */
  // width: 620px;/*需要配合宽度来使用*/
}
.search .comUl li span {
  float: right;
  font-size: 15px;
}
.search .pagin {
  margin-top: 20px;
}
.search .pagin .el-pagination {
  text-align: center;
}
.searchTxt {
  margin-right: 90px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (max-width: 1300px) {
  .search .navMain,
  .companyProfile,
  .search .main_panel {
    width: 1000px;
  }
  .search .navMainLeft,
  .search .navMainRight {
    width: auto;
  }
  .search #PageRight {
    width: 708px;
  }
}
@media (max-width: 992px) {
  .search .navMain,
  .companyProfile,
  .search .main_panel {
    width: 96%;
  }
  .companyProfile {
    padding: 20px 20px;
  }
  .companyProfile .contactBody video {
    max-width: 100% !important;
  }
  .companyProfile img {
    max-width: 100% !important;
  }
  .search .ziHead {
    height: 362px;
  }
  .search #PageRight {
    width: 100%;
  }
  .search #PageLeft {
    display: none;
  }
}
@media (max-width: 768px) {
  .search .navMainLeft {
    width: 100%;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .search .navMainRight {
    display: none;
  }
  .search .ziHead {
    height: 200px;
  }
  .search .ziContact {
    padding: 0;
    margin: 0;
  }
  .companyProfile {
    padding: 20px 10px;
  }
  .contactBody h1 {
    font-size: 22px !important;
  }
}
</style>
